<template>
  <div class="blog-detail-wrapper">
    <b-row>
      <!-- blogs -->
      <b-col cols="12">
        <b-card>
          <b-card-body>
            <div class="mb-2">
              <b-badge
                  pill
                  class="mr-75"
                  :variant="getValueFromSource(faq, 'is_active') ? 'light-success' : 'light-danger'"
              >
                {{ getValueFromSource(faq, 'is_active') ? 'Active' : 'Inactive' }}
              </b-badge>
            </div>

            <b-card-title>
              {{ getValueFromSource(faq, 'title') }}
            </b-card-title>

            <b-card-text class="blog-content-truncate">
              {{ extractTextContentFromHTML(getValueFromSource(faq, 'content')) }}
            </b-card-text>

            <div class="">
              <small class="text-muted">Last updated {{ formatDate(getValueFromSource(faq, 'modified'), 'MMM dd, yy') }}</small>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <!--/ blogs -->
    </b-row>
  </div>
</template>

<script>
import {
  BOverlay,
  BAlert,
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
  BCardBody, BCardTitle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'


export default {
  components: {
    BOverlay,
    BAlert,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BDropdown,
    BCardBody,
    BCardTitle,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    faq: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  methods: {
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
