<template>
  <content-with-sidebar class="cws-container cws-sidebar-right blog-wrapper">
    <b-overlay :show="loading">
      <b-alert
          variant="danger"
          :show="faq === undefined && !loading"
      >
        <h4 class="alert-heading">
          Error fetching Blog Post Details
        </h4>
        <div class="alert-body">
          <b-link
              class="alert-link"
              :to="{ name: 'admin-faq'}"
          >
            List Faqs
          </b-link>
        </div>
      </b-alert>
      <faq-details v-if="faq" :faq="faq" @refresh="fetchFaq" />
    </b-overlay>
    <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
      <div class=" ">
        <div class="mb-2">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 w-100"
              :to="{ name: 'admin-update-faq', params: { id: getValueFromSource(faq, 'id') } }"
              :disabled="!canUpdateFaq"
          >
            Edit
          </b-button>
        </div>

        <div>
          <b-button
              variant="danger"
              class="w-100"
              :disabled="!canDeleteFaq"
              @click="onDeleteFaq"
          >
            Delete
          </b-button>
        </div>
      </div>

    </div>
  </content-with-sidebar>
</template>

<script>
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import {
  BAlert, BBadge, BButton,
  BCard, BCardText,
  BCol,
  BFormInput,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BOverlay,
  BRow
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { get } from "lodash";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { kFormatter } from "@core/utils/filter";
import FaqDetails from "@core/components/shared/faqs/FaqDetails.vue";

export default {
  components: {
    FaqDetails,
    BOverlay,
    BAlert,
    BFormInput,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
    BCardText,
    ContentWithSidebar,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      faq: {},
      recentBlogPosts: [],
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      loading: false,
    }
  },
  computed: {
    canUpdateFaq(){
      return this.can("faqs:CanUpdate")
    },
    canDeleteFaq(){
      return this.can("faqs:CanDelete")
    }
  },
  created() {
    this.fetchFaq();
  },
  methods: {
    async fetchFaq() {
      try {
        this.loading = true;

        const { id } = this.$route.params;
        const { data } = await this.useJwt().adminService.fetchFaq(id);
        this.faq = data;
      } catch (error) {
        this.faq = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchRecentPosts() {
      try {
        this.recentsLoading = true;

        const request = await this.useJwt().sharedService.fetchRecentBlogPosts();
        const { data } = request.data;

        this.recentBlogPosts = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.recentsLoading = false;
      }
    },
    async onDeleteFaq(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Deletion?',
          text: "This will delete this faq .",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const { _id: faq_id } = this.faq;
        await this.useJwt().adminService.deleteFaq(faq_id);

        await this.$router.push({
          name: "admin-faqs"
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Faq deleted successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-blog.scss';
</style>
